export const translation = {
    methods: {
    /**
     * Translate the given key.
     */
        __(key, replace = {}) {
            let translation = this.$page.props.translations.strings[key]
                ? this.$page.props.translations.strings[key]
                : key;

            Object.keys(replace).forEach((key) => {
                translation = translation.replace(`:${key}`, replace[key]);
            });

            return translation;
        },
    },
};
